<template>
    <div class="view content-padding">
        <TableOverview
            kind="space"
            :page-title="labels.spaces + ` ` + labels.overview"
            :searchable="true"
            :settings="settings"
            :footer-buttons="footerButtons"
            :get-page="getEntityList"
            :isDataLoaded="isDataLoaded"
        />
        <router-view
            name="create_space"
            modal-id="create-space"
            kind="space"
            :route-back="{ name: 'spaces-overview' }"
            :title="labels.create_new_space"
            :title-placeholder="labels.title"
            :text-placeholder="labels.excerpt"
            :required-fields="['title']"
            :required-data-set="['title']"
            :custom-fields="spaceCustomFields"
            @entity-created="entityCreated"
        />
    </div>
</template>

<script>
    const colors = require("/colors.config.json");
    import { mapActions, mapMutations, mapState, mapGetters } from "vuex";

    export default {
        name: "SpacesOverview",
        components: {
            TableOverview: () => import("@/views/Dashboard/Common/TableOverview/"),
        },
        data(){
            return {
                isDataLoaded: false
            }
        },
        computed: {
            ...mapState("helper", ["admin_roles"]),
            ...mapGetters("helper", ["labels"]),
            footerButtons() {
                if (this.hasRole("super"))
                return [
                    {
                        label: "Create new",
                        variant: "btn-purple",
                        action: () => {
                            if (this.$route.name !== "create-space") {
                                this.$router.push({
                                    name: "create-space",
                                    query: {
                                        page: this.$route.query.page,
                                    }
                                });
                            }
                        }
                    }
                ];

                return [];
            },
            settings() {
                return {
                    header: [
                        {
                            headerLabel: this.labels.name,
                            headerColor: `${colors.gray_text}`,
                            key: "title",
                            computeFn: (input) => input,
                            bodyColor: `${colors.dark}`,
                            columnWidth: "1 1 30%",
                            whiteSpace: "nowrap",
                        }
                    ],
                    sortable: false,
                    // sortStyles: { flex: '0 0 5%', color: `${colors.gray_text}` },
                    minTableWidth: "300px",
                    actionsWidth: "0 0 10%",
                    actionsHeaderLabel: "",
                    actions: [
                        {
                            label: this.labels.open,
                            variant: "btn-purple",
                            styles: { padding: "8px 15px", fontWeight: "700" },
                            action: (data) =>
                            this.$router.push({
                                name: "space-single",
                                params: { id: data._id },
                            }),
                        }
                    ]
                };
            },
            spaceCustomFields() {
                return [
                    {
                        type: "textarea",
                        name: "excerpt",
                        label: this.labels.excerpt,
                        class: "mt-4",
                        required: false
                    }
                ];
            }
        },
        methods: {
            ...mapMutations("helper", ["setTablePagination"]),
            ...mapActions("notification", ["addNotification"]),
            ...mapActions("space", ["getSpacesList"]),
            entityCreated() {
                this.getEntityList(this.$route.query.page);
            },
            getEntityList(page) {
                this.isDataLoaded = false
                let data = { page };

                if (!this.$route.query.page) data.page = 1;
                else if (!page) data.page = this.$route.query.page;

                this.getSpacesList(data).then((res) => {
                    if (res.success) {
                        this.setTablePagination(res.data.pages);
                        this.isDataLoaded = true
                        if (
                            res.data?.pages?.current && Number(this.$route.query.page) !== Number(res.data.pages.current)
                        ) {
                            this.$router.push({ query: { page: res.data.pages.current } });
                        }
                    } else if (res.error) {
                        this.addNotification({
                            variant: "danger",
                            msg: res.error,
                            labels: this.labels,
                        });
                    }
                }).catch((err) => console.log(err));
            },
            hasRole(role) {
                if (Array.isArray(this.admin_roles) && this.admin_roles.includes(role))
                return true;

                return false;
            }
        },
        mounted() {
            this.getEntityList();
        }
    };
</script>

<style lang="scss" scoped></style>
